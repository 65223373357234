.project-detail {
  padding: 80px 20px;
  background-color: #2E2F34;
  color: white;
  min-height: 100vh;
}

.project-header {
  max-width: 1200px;
  margin: 0 auto 60px;
}

.project-title {
  font-size: 3rem;
  margin-bottom: 30px;
  position: relative;
  display: inline-block;
}

.project-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100px;
  height: 3px;
  background: orange;
}

.project-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto 60px;
}

.gallery-item {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.image-wrapper {
  position: relative;
  padding-top: 75%;
  overflow: hidden;
}

.project-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease;
}

.zoom-icon {
  color: white;
  text-align: center;
}

.zoom-icon i {
  font-size: 2rem;
  margin-bottom: 10px;
}

.zoom-icon span {
  display: block;
  font-size: 0.9rem;
}

.gallery-item:hover .project-image {
  transform: scale(1.1);
}

.gallery-item:hover .image-overlay {
  opacity: 1;
}

.project-content {
  max-width: 800px;
  margin: 0 auto;
}

.content-section {
  margin-bottom: 60px;
}

.section-title {
  color: orange;
  font-size: 2rem;
  margin-bottom: 20px;
}

.project-description {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 30px;
}

.project-features ul {
  list-style: none;
  padding: 0;
}

.project-features li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  line-height: 1.6;
}

.project-features li::before {
  content: '→';
  position: absolute;
  left: 0;
  color: orange;
}

/* Image Viewer Styles */
.image-viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease;
}

.close-viewer-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: orange;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.close-viewer-button:hover {
  background: orange;
  transform: rotate(90deg);
}

.image-counter {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  z-index: 1001;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .project-title {
    font-size: 2rem;
  }

  .project-gallery {
    grid-template-columns: 1fr;
  }

  .content-section {
    padding: 0 20px;
  }

  .gallery-item {
    margin-bottom: 20px;
  }

  .zoom-icon i {
    font-size: 1.5rem;
  }

  .zoom-icon span {
    font-size: 0.8rem;
  }

  .close-viewer-button {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
}

/* Animasi untuk Image Viewer */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  