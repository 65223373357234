.certificate {
  padding: 80px 20px;
  background-color: #2E2F34;
  min-height: 100vh;
  position: relative;
}

.certificate-container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 60px;
  text-align: center;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: orange;
}

.certificate-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
  padding: 20px;
}

.certificate-card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.certificate-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.certificate-image-wrapper {
  position: relative;
  padding-top: 75%;
  overflow: hidden;
}

.certificate-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.certificate-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease;
}

.zoom-icon {
  color: white;
  text-align: center;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.zoom-icon i {
  font-size: 2rem;
  margin-bottom: 10px;
}

.zoom-icon span {
  display: block;
  font-size: 0.9rem;
}

.certificate-card:hover .certificate-overlay {
  opacity: 1;
}

.certificate-card:hover .zoom-icon {
  transform: translateY(0);
}

.certificate-card:hover .certificate-image {
  transform: scale(1.1);
}

/* Image Viewer Styles */
.certificate-viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.close-viewer-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: orange;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.close-viewer-button:hover {
  background: orange;
  transform: rotate(90deg);
}

.image-counter {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  z-index: 1001;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .certificate-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    padding: 15px;
  }

  .section-title {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .zoom-icon i {
    font-size: 1.5rem;
  }

  .zoom-icon span {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .certificate-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
  }

  .section-title {
    font-size: 1.8rem;
  }
}