/* src/components/Style/Home.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; 
}

.intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.intro .text {
  flex: 1;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: 100vh;
  padding: 60px;
  background-color:#2E2F34 ;
  text-align: left;
}

.about {
  padding: 80px;
  background-color: #34353A;
  text-align: center;
  padding-top: 110px;
  padding-bottom: 200px;
  color: white;
  opacity: 1;
  transition: opacity 1s ease; /* Pastikan transition untuk opacity sudah ada */
}

.about.show {
  opacity: 1;
}

.about.fade-out {
  opacity: 1; /* Opacity menjadi 0 saat class fade-out ditambahkan */
}

.about .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px; /* Atur margin sesuai kebutuhan */
}

.about .icons .icon {
  width: 100px; /* Sesuaikan ukuran ikon */
  height: auto;
  margin: 0 60px; /* Atur margin sesuai kebutuhan */
}

.my-photo {
  width: 700px; /* Sesuaikan ukuran foto Anda */
  height: auto;
  margin-right: -100px; /* Atur margin sesuai kebutuhan */
  margin-top: 40px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dynamic-text {
  display: inline-block;
  animation: fadeInOut 2s linear infinite;
}

/* myskill */

/* Existing styles */

.myskill {
  background-color: #2E2F34;
  text-align: center;
  padding-top: 100px; /* Adjust the value as needed to move the heading down */
  margin-bottom: 50px;
  
}
.myskill h2 {
  margin-bottom: 100px; /* Adjust the value as needed */
}


.skill-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  color: white;
}

.circle-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin: 10px;
}

.circle {
  position: relative;
  width: 100px;
  height: 100px;
}

.mask,
.fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
}

.mask {
  clip: rect(0px, 100px, 100px, 50px);
}

.fill {
  clip: rect(0px, 50px, 100px, 0px);
  background: orange; /* Adjust the color as needed */
}

.inside-circle {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: #34353A;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10%;
  left: 10%;
  font-size: 1.2em;
  font-weight: bold;
  opacity: 1;
}

.skill-name {
  margin-top: 10px;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
}


@media screen and (max-width: 500px) {

  .intro {
    display: inline;
  }
  
  .intro .text {
    flex: 1;
  }
  .about .icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .about .icons .icon {
    width: 50px; 
    height: auto;
    margin: 10px;


  }
  .my-photo {
    opacity: 1;
  }
  .myskill {
    background-color: #2E2F34;
    text-align: center;
    padding-top: 20px; /* Adjust the value as needed to move the heading down */
    margin-bottom: 50px;
    opacity: 0;

    
  }
  .myskill h2 {
    margin-bottom: 100px; /* Adjust the value as needed */
  }
  
  
  .skill-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 5px;
    color: white;
  }
  
  .circle-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    margin: 10px;
  }
  
  .circle {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .mask,
  .fill {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }
  
  .mask {
    clip: rect(0px, 100px, 100px, 50px);
  }
  
  .fill {
    clip: rect(0px, 50px, 100px, 0px);
    background: orange; /* Adjust the color as needed */
  }
  
  .inside-circle {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 1.2em;
    font-weight: bold;
    opacity: 1;
  }
  
  .skill-name {
    margin-top: 10px;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
  }
}


@media screen and (max-height: 1600px) {
  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 80vh;
    padding: 60px;
    background-color:#2E2F34 ;
    text-align: left;
  }
  .about {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      height: 100vh;
      padding: 60px;
      background-color:#34353A ;
      text-align: center;
  }
  .myskill{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 82vh;
    padding: 60px;
    background-color:#2E2F34 ;
    text-align: center;
  }
}
@media screen and (max-width: 450px) {

  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100vh;
    padding: 60px;
    background-color:#2E2F34 ;
    text-align: left;
  }
  
  .about {

    background-color: #34353A;
    text-align: center;
    padding-top: 70lvh;
    padding-bottom: 70lvh;
    color: white;
    opacity: 1;
    transition: opacity 1s ease; /* Pastikan transition untuk opacity sudah ada */
  }
  .about p {
    font-size: 10px;
  }
  .intro {
    display: inline;
  }
  
  .intro .text {
    flex: 1;
  }
  .about .icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .about .icons .icon {
    width: 50px; 
    height: auto;
    margin: 10px;


  }
  .my-photo {
    opacity: 1;
  }
  .myskill {
    background-color: #2E2F34;
    text-align: center;
    padding-top: 80lvh; /* Adjust the value as needed to move the heading down */
    margin-bottom: 70lvh;
    opacity: 0;

    
  }
  .myskill h2 {
    margin-bottom: 100px; /* Adjust the value as needed */
  }
  
  
  .skill-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 5px;
    color: white;
  }
  
  .circle-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    margin: 10px;
  }
  
  .circle {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .mask,
  .fill {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }
  
  .mask {
    clip: rect(0px, 100px, 100px, 50px);
  }
  
  .fill {
    clip: rect(0px, 50px, 100px, 0px);
    background: orange; /* Adjust the color as needed */
  }
  
  .inside-circle {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 1.2em;
    font-weight: bold;
    opacity: 1;
  }
  
  .skill-name {
    margin-top: 10px;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
  }
}
@media screen and (max-width: 400px) {

  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 70lvh;
    padding: 10px;
    background-color:#2E2F34 ;
    text-align: left;
  }
  
  .about {
    background-color: #34353A;
    text-align: center;
    padding-top: 50lvh;
    padding-bottom: 50lvh;
    color: white;
    opacity: 1;
    transition: opacity 1s ease; /* Pastikan transition untuk opacity sudah ada */
  }
  .about p {
    font-size: 10px;
  }
  .intro {
    display: inline;
  }
  
  .intro .text {
    flex: 1;
  }
  .about .icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .about .icons .icon {
    width: 50px; 
    height: auto;
    margin: 10px;


  }
  .my-photo {
    opacity: 1;

  }
  .myskill {
    background-color: #2E2F34;
    text-align: center;
    padding-top: 40lvh; /* Adjust the value as needed to move the heading down */
    margin-bottom: 30lvh;
    opacity: 0;

    
  }
  .myskill h2 {
    margin-bottom: 100px; /* Adjust the value as needed */
  }
  
  
  .skill-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 5px;
    color: white;
  }
  
  .circle-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    margin: 10px;
  }
  
  .circle {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .mask,
  .fill {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }
  
  .mask {
    clip: rect(0px, 100px, 100px, 50px);
  }
  
  .fill {
    clip: rect(0px, 50px, 100px, 0px);
    background: orange; 
  }
  
  .inside-circle {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: #34353A;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 1.2em;
    font-weight: bold;
    opacity: 1;
  }
  
  .skill-name {
    margin-top: 10px;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
  }
}

.cta-buttons {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
}

.primary-btn, .secondary-btn {
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.primary-btn {
  background-color: orange;
  color: white;
}

.secondary-btn {
  border: 2px solid orange;
  color: orange;
}

.primary-btn:hover, .secondary-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 107, 107, 0.3);
}

.tech-stack {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;
}

.tech-icon {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.tech-icon:hover {
  transform: translateY(-5px);
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.skill-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.skill-icon {
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skill-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.skill-icon i {
  font-size: 2.5rem;
  color: orange;
}

.skill-card h3 {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
}

.skill-item {
  margin-bottom: 30px;
}

.skill-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.skill-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
}

.skill-percentage {
  color: orange;
}

.skill-bar {
  height: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.skill-progress {
  height: 100%;
  border-radius: 5px;
  transition: width 1s ease-in-out;
}

.section-title {
  text-align: center;
  margin-bottom: 3rem;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.myskill-section {
  background-color: #2E2F34;
  padding: 100px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.section-title {
  text-align: center;
  margin-bottom: 60px;
}

.skills-container {
  max-width: 800px;
  margin: 0 auto;
}

.skill-item {
  margin-bottom: 30px;
}

.skill-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.skill-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
}

.skill-percentage {
  color: orange;
  font-weight: 500;
}

.skill-bar {
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.skill-progress {
  height: 100%;
  border-radius: 10px;
  transition: width 1.5s ease-in-out;
  position: relative;
}

.skill-progress::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, 
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.1) 100%
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .myskill-section {
    padding: 60px 0;
  }

  .skill-item {
    margin-bottom: 25px;
  }

  .skill-name {
    font-size: 1rem;
  }

  .skill-bar {
    height: 6px;
  }
}

@media screen and (max-width: 480px) {
  .myskill-section {
    padding: 40px 0;
  }

  .container {
    padding: 0 15px;
  }

  .skill-item {
    margin-bottom: 20px;
  }
}