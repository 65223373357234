/* src/components/Style/Contact.css */
.contact-section {
  padding: 80px 20px;
  background-color: #2E2F34;
  min-height: 100vh;
  color: white;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: orange;
}

.contact-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
  margin-top: 40px;
}

.contact-info {
  padding: 30px;
}

.info-item {
  margin-bottom: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
}

.info-item:hover {
  transform: translateY(-5px);
}

.info-item i {
  font-size: 2rem;
  color: orange;
  margin-bottom: 15px;
}

.info-item h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.contact-form {
  background: rgba(255, 255, 255, 0.05);
  padding: 40px;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.form-group {
  margin-bottom: 25px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  color: #fff;
  font-size: 1rem;
}

.form-group label i {
  margin-right: 8px;
  color: orange;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px 15px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: orange;
  background: rgba(255, 255, 255, 0.15);
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.submit-button {
  width: 100%;
  padding: 15px;
  background: orange;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button:hover {
  background-color: orange;
}

.submit-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button.disabled:hover {
  transform: none;
  box-shadow: none;
}
