.footer {
    position: relative; 
    bottom: 0; 
    left: 0px;
    width: 100%;
    background-color: #2E2F34;
    color: #000000;
    padding: 20px;
    text-align: center;
    display: flex; 
    justify-content: space-between; 
}

.social-icons {
    display: flex;
    flex-direction: row; 
}

.social-icons a {
    color: #ffff;
    margin-right: 20px; 
    font-size: 20px;
}

.social-icons a:hover {
    color: orange; 
}
